import qs from 'qs'
import _IsArray from 'lodash/isArray'
import _Get from 'lodash/get'
import _SortBy from 'lodash/sortBy'
import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import classnames from 'classnames'

import { search, clearSearch } from '../home/actions'
import { fetchProducts, fetchAllProducts, updateFilters } from './actions'
import { prepareMarketingClaims } from '../../helpers/normalizer'

import './products.scss'

import noArtifFlav from '../../assets/img/badges/badge-no-artif-flavors.svg'
import noArtifPreserv from '../../assets/img/badges/badge-no-artif-preservs.svg'
import noSynthColors from '../../assets/img/badges/badge-no-synt-colors.svg'
import noHfcs from '../../assets/img/badges/badge-no-hfcs.svg'
import noMsg from '../../assets/img/badges/badge-no-added-msg.svg'
import fatFree from '../../assets/img/badges/badge-fat-free.svg'
import lite from '../../assets/img/badges/badge-lite.svg'
import noEdta from '../../assets/img/badges/badge-no-edta.svg'

const attribute = [
    { id: 'shelfStable', name: 'Shelf Stable', value: 'Yes' },
    { id: 'refrigerated', name: 'Refrigerated', value: 'Yes' },
    { id: 'gluten', name: 'Gluten free', value: 'Yes' },
    { id: 'kosher', name: 'Kosher', value: 'Yes' },
    { id: 'kosherDairy', name: 'Kosher Dairy', value: 'Yes' },
    { id: 'edta', name: 'No EDTA', value: 'No' },
    { id: 'hfcs', name: 'No HFCS', value: 'No' },
    { id: 'msg', name: 'No added MSG', value: 'No' },
    { id: 'artificialFlavors', name: 'No artificial flavors', value: 'No' },
    {
        id: 'artificialPreservatives',
        name: 'No artificial preservatives',
        value: 'No',
    },
    {
        id: 'syntheticColors',
        name: 'No synthetic colors',
        value: 'No',
    },
]

const Filters = lazy(() => import('./components/Filters'))
const Search = lazy(() => import('../../components/search/Search'))

class AllProductsPage extends Component {
    componentDidMount() {
        const {
            location: { search, state = {} },
            allProducts,
            searchResults,
        } = this.props

        const defaultValues = {
            flavor: null,
            package: null,
            product: null,
            attribute: null,
            productLines: null,
        }

        window.scrollTo(0, 0)

        // parse query string from URL
        const queryParams = this.queryToFilters(search)

        this.props.updateFilters({ ...defaultValues, ...state, ...queryParams })

        if (!allProducts.length) this.props.fetchAllProducts()

        if (Object.keys(searchResults).length) this.props.clearSearch()
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname, state, search },
            filters,
            history,
        } = this.props

        const hasPropsChanged =
            filters.flavor !== prevProps.filters.flavor ||
            filters.package !== prevProps.filters.package ||
            filters.product !== prevProps.filters.product ||
            filters.attribute !== prevProps.filters.attribute ||
            filters.productLines !== prevProps.filters.productLines

        const hasStateChanged =
            state &&
            (state.flavor !== filters.flavor ||
                state.package !== filters.package ||
                state.product !== filters.product ||
                state.attribute !== filters.attribute ||
                state.productLines !== filters.productLines)

        if (hasStateChanged) this.props.updateFilters(state)

        if (hasPropsChanged) {
            const newPage = Object.assign({}, this.props.paging, { page: 1 })
            this.props.fetchProducts(filters, newPage, true)

            const queryStringified = this.filtersToQuery(filters)

            // only update the URL if the query string has changed
            if (search !== `?${queryStringified}`) {
                history.push({
                    pathname: pathname,
                    search: `?${queryStringified}`,
                })
            }
        }
    }

    // Convert filters to query string
    filtersToQuery(filters) {
        const queryParams = {
            flavor: filters.flavor,
            package: filters.package,
            attribute: filters.attribute,
            productLines: filters.productLines,
        }

        // filter out empty parameters
        const filteredParams = Object.keys(queryParams)
            .filter((key) => queryParams[key])
            .reduce((obj, key) => {
                obj[key] = queryParams[key]
                return obj
            }, {})

        return qs.stringify(filteredParams, { arrayFormat: 'comma' })
    }

    // Convert query string to filters
    queryToFilters(query) {
        const parsedParams = qs.parse(query, {
            ignoreQueryPrefix: true,
        })

        // split comma-separated values into arrays
        Object.keys(parsedParams).forEach((key) => {
            if (
                typeof parsedParams[key] === 'string' &&
                parsedParams[key].includes(',')
            ) {
                parsedParams[key] = parsedParams[key].split(',')
            } else if (key !== 'attribute' && !_IsArray(parsedParams[key])) {
                parsedParams[key] = [parsedParams[key]]
            }
        })

        return parsedParams
    }

    handleSearchChange(searchTerm) {
        searchTerm && this.props.search(searchTerm)
    }

    parseProductPath(product) {
        const { slug } = product
        return `products/${slug}`
    }

    renderMarketingAllergens(product) {
        if (!product) return

        const results = []
        const {
            claims: { marketing },
        } = product
        const marketingImgs = {
            fatFree: fatFree,
            lite: lite,
            msg: noMsg,
            hfcs: noHfcs,
            edta: noEdta,
            artificialFlavors: noArtifFlav,
            syntheticColors: noSynthColors,
            artificialPreservatives: noArtifPreserv,
        }

        Object.keys(marketingImgs).forEach((ent, i) =>
            marketing[ent] === (ent.match(/fatFree|lite/) ? 'Yes' : 'No')
                ? results.push(
                      <img
                          src={marketingImgs[ent]}
                          alt={`No${ent}`}
                          key={`marketing-${i}`}
                      />
                  )
                : null
        )

        return !!results.length ? (
            <div className="allergens">{results}</div>
        ) : null
    }

    renderStorage(product) {
        if (!product) return

        const {
            storage: { storage },
        } = product

        return (
            <div className="marketing-claims">
                {storage.includes('Dry storage at ambient temperature') &&
                    <div>
                        <span>Shelf stable</span>
                    </div>
                }
                {storage.includes('Product requires refrigerated storage') &&
                    <div>
                        <span>Refrigerated</span>
                    </div>
                }
            </div>
        );
    }

    formatProductSize(product) {
        const {
            caseInfo: {
                packageType,
                individualUnitVal,
                individualUnitPercent: appender,
            },
        } = product
        return packageType === 'cup'
            ? `${packageType} (${individualUnitVal} ${
                  appender === 'ONZ' ? 'oz' : appender
              })`
            : packageType
    }

    renderProducts() {
        const {
            products,
            noResults,
            paging: { loading },
        } = this.props

        if (!loading && noResults) return <p>Sorry, no results were found</p>

        return products.map((item, idx) => (
            <Link
                to={{
                    pathname: this.parseProductPath(item),
                    state: { productCode: item.productCode },
                }}
                className={classnames({
                    'product-item': true,
                    new: item.productCode === 'KE5052' || item.productCode === 'KE5052ZY' || item.productCode === 'KE5052B3' || item.productCode === 'KE5060B3'
                })}
                key={idx}
            >
                <div>
                    <div className="product-img-container">
                        <img
                            className="product-img"
                            src={item.images.websiteThumbnail}
                            alt=""
                        />
                        <span>{this.formatProductSize(item)}</span>
                    </div>
                    <div className="block-info">
                        <h3>{item.name}</h3>
                        <div className="additional-info">
                            <div>
                                <span>Product code:</span>
                                <div>
                                    <span className="product-code">{item.productCode}</span>
                                </div>
                            </div>
                            <div>
                                <span>Case GTIN:</span>
                                <div>
                                    <span className="product-gtin">{item.caseInfo.caseGtin}</span>
                                </div>
                            </div>
                        </div>
                        {prepareMarketingClaims(item.claims.marketing)}
                        {this.renderStorage(item)}
                    </div>
                </div>
                {this.renderMarketingAllergens(item)}
            </Link>
        ))
    }

    onFilterChange(filterBy, options) {
        let newFilter = {}

        newFilter[filterBy] = null

        if (options) {
            if (options[filterBy] && Array.isArray(options[filterBy])) {
                newFilter = {
                    attribute: null,
                    flavor: null,
                    package: null,
                    productLines: null,
                }

                Object.keys(options).forEach((key) => {
                    if (options[key]) {
                        if (Array.isArray(options[key])) {
                            if (key === 'attribute') {
                                newFilter[key] = {}
                                options[key].forEach((o) => {
                                    newFilter[key][o.id] = o.value
                                })
                            } else {
                                newFilter[key] = options[key].map(
                                    (o) => o.id || o
                                )
                            }
                        } else {
                            newFilter[key] =
                                key === 'attribute'
                                    ? options[key]
                                    : [options[key]]
                        }
                    }
                })
            }
        }

        this.props.updateFilters(
            Object.assign({}, this.props.filters, newFilter)
        )
        this.props.history.push(
            '/products',
            Object.assign({}, this.props.filters, newFilter)
        )
    }

    onFilterClear() {
        const filters = {
            attribute: null,
            flavor: null,
            package: null,
            productLines: null,
        }

        this.props.updateFilters(filters)
        this.props.history.push('/products', filters)
    }

    handleInfiniteLoad() {
        const {
            paging: { hasNext, loading },
        } = this.props
        if (hasNext && !loading) {
            const newPage = Object.assign({}, this.props.paging, {
                page: this.props.paging.page + 1,
            })
            this.props.fetchProducts(this.props.filters, newPage, false)
        }
    }

    handleProductRedirect(opt) {
        if (!opt) return
        this.props.history.push(`/products/${opt.id}`)
    }

    render() {
        return (
            <div>
                <div className="body-wrapper products light">
                    <h1>We’ve got what you’re looking for</h1>
                    <div className="descr">
                        Whether it’s a classic Caesar dressing in a 1.5 ounce
                        cup, or our famous Boom Boom Sauce, you’ll find it here.
                        Easily.
                    </div>
                    <Suspense
                        fallback={<p className="loading">Loading search...</p>}
                    >
                        <Search
                            byType="products"
                            className="search-wrapper wide"
                            results={this.props.searchResults}
                            loading={this.props.loadingResults}
                            placeholder={
                                'Product name/code, flavor type, UPC code and more...'
                            }
                            onChange={(e) => {
                                this.handleSearchChange(e.target.value)
                            }}
                        />
                    </Suspense>
                    <Suspense
                        fallback={<p className="loading">Loading filters...</p>}
                    >
                        <Filters
                            attribute={attribute}
                            flavors={_SortBy(this.props.flavors, 'name')}
                            products={_SortBy(this.props.allProducts, 'name')}
                            productLines={_SortBy(
                                this.props.productLines,
                                'name'
                            )}
                            selections={this.props.filters}
                            onFilterClear={() => this.onFilterClear()}
                            onFilterChange={(f, o) => this.onFilterChange(f, o)}
                            packagings={_SortBy(
                                this.props.packagings,
                                'displayOrder'
                            )}
                            handleProductRedirect={(opt) =>
                                this.handleProductRedirect(opt)
                            }
                        />
                    </Suspense>
                    <div className="product-list">
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => this.handleInfiniteLoad()}
                            hasMore={this.props.paging.hasNext}
                            loader={
                                <div className="loader" key={0}>
                                    Loading ...
                                </div>
                            }
                        >
                            {this.renderProducts()}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    flavors: state.lookup.flavors,
    product: state.lookup.product,
    packagings: state.lookup.packagings,
    productLines: state.lookup.productLines,

    routedFlavor: _Get(state, 'router.location.state.flavors'),
    products: _Get(state, 'products.list', []),
    allProducts: _Get(state, 'products.all', []),
    filters: _Get(state, 'products.filters', {}),
    paging: _Get(state, 'products.paging', {}),
    noResults: _Get(state, 'products.noResults', {}),

    searchResults: _Get(state, 'home.searchResults'),
    loadingResults: _Get(state, 'home.loadingResults'),
})

export default connect(mapStateToProps, {
    search,
    clearSearch,
    fetchProducts,
    fetchAllProducts,
    updateFilters,
})(AllProductsPage)
