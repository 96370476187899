import React, { PureComponent } from 'react';

class Privacy extends PureComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="body-wrapper pampas">
                <h2>Privacy policy</h2>
                <p>This Privacy Policy (“Policy”) discloses the practices of Ken’s Foods, LLC. (“Ken’s”) concerning personal information we obtain about you by and through use of Ken’s websites. By using any of Ken’s websites (the “Site”), or using other services of Ken’s (collectively the “Services”), you are accepting the practices described in this Policy.</p>
                <p>This Policy notifies you of: (a) what personal information of yours is collected when you access this website and/or use the Services; (b) how the information is collected; (c) how the information is used and shared; (d) our efforts to maintain the security of Sensitive Personal Information; (e) our compliance with applicable data privacy regulations; and (e) other details concerning your personal information and our policies.</p>
                <p>This Policy does not apply to the practices of companies that Ken’s does not own or control, or to people that we do not employ or manage. Information contained in this Policy may be changed or updated without notice at any time.</p>
                <p>1. Information Collection and Use<br />
                Information collected is stored in a secure database, accessible only by Ken’s and its authorized third parties or agents. Ken’s may collect information, as discussed below, from those persons who use, attempt to use, or access Ken’s Site or Services (“Users”):</p>
                <p>a. Information that may be gathered online<br />
                This Site may collect the following types of information from users who visit the Site: (1) information gathered by analyzing page views and user navigation patterns, (2) information that users provide directly when they use any of our Services. We use page view and navigation information to ensure that users can find information on our Site as quickly as possible. Those users wishing to post to our Site or subscribe to Services may be required to register or otherwise provide personal information. Certain other services may also require collecting personal information and/or registration. These services include, but are not limited to, subscribing to our “Ken’s Corner” mailing lists. If you choose not to register and provide personal information, you may not have access to those areas or products that require registration information.</p>
                <p>b. Our Services<br />
                When a User registers for certain services, the User may be asked to provide certain information, including, but not limited to, name, address, zip code, telephone number, email address (“Sensitive Personal Information”). Once you register with us, you are not anonymous to us.</p>
                <p>c. Cookies<br />
                A cookie is a short line of text that is created by a web site and stored in a file on your computer. Cookies are used to store information about your preferences and activity during and after your visit to a web site. Cookies are also used widely in electronic commerce programs to keep track of which items a user has selected for purchase. Cookies cannot gather any information from your computer other than which you provide directly or that which is provided routinely by your browser software. Any user has the right and ability to refuse or delete cookies. However, such refusal or deletion may prevent the user from properly using the site’s cookie-dependent features. While cookies may be passively collected while using Ken’s Site, Ken’s does not use cookies to track or monitor your use of the Site.</p>
                <p>2. Use of Information<br />
                Information we collect is generally used or may be used for the following purposes:</p>
                <p>To contact you with newsletters and information regarding Ken’s products that we believe will be of interest to you.</p>
                <p>To build features that will make the services available on our site easier to use. This includes faster search requests, better member support and timely notice of new services and special offers.<br />
                To improve our marketing and promotional efforts, improve our content and product offerings, and customize our sites content, layout, and services. These uses improve our Services and better tailor them to meet your needs.<br />
                To resolve disputes, troubleshoot problems and enforce our Terms and Conditions of Use. Sharing of Information. Except as otherwise described in this Policy, or if we inform you otherwise at the time of data collection, we will not share your information with third parties. Except for the information you choose to publicly disclose on the Ken’s Site, the information we collect from you is not shared with any other marketing partners or unauthorized third parties.<br />
                We reserve the right to disclose personal information, including Sensitive Personal Information, when we believe that such disclosure is appropriate to comply with the law or a request by a government official, to protect the rights or property of Ken’s, or to enforce our Terms and Conditions of Use. You cannot opt out of disclosure of your personal information if required by lawful order. In the event that Ken’s or substantially all of its assets are acquired by a third party, member information and Sensitive Personal Information may be some of the transferred assets.</p>
                <p>3. Agents<br />
                We may employ other companies and individuals to perform functions on behalf of Ken’s. Examples include sending postal mail and e-mail, removing repetitive information from member lists, analyzing data, providing marketing assistance, processing credit card payments, and providing member services. Such agents may have access to personal and Sensitive Personal Information needed to perform their functions, but may not use it for other purposes.</p>
                <p>4. Special Offers and Announcements<br />
                For those who choose to register, we may send newsletters, emails, and other product or service information, including special offers and announcements. If you no longer wish to receive these types of communications from us, please notify service@kensfoods.com.</p>
                <p>5. Security<br />
                Ken’s takes every precaution to protect our User’s Sensitive Personal Information, both online and offline. Your Sensitive Personal Information is password-protected for your privacy and security.â€¨â€¨Sensitive Personal Information is also protected offline in our offices. Only Ken’s employees who need the information to perform a specific job are granted access to Sensitive Personal Information. Furthermore, all Ken’s employees are kept up-to-date on our security practices and changes in those practices. Finally, the servers that Ken’s uses to store Sensitive Personal Information on are kept in a secure environment. If you have any questions about the security at our website, you can send an email to service@kensfoods.com.</p>
                <p>Every User who has an account on this Site is responsible for (i) keeping his or her account password confidential and secured, (ii) restricting access to his or her computer; and (iii) keeping the e-mail address associated with that account current.</p>
                <p>Your user name and password are for your use only. You may not disclose your password to any other person. Your participation implies your agreement to the terms included in this Privacy Policy.</p>
                <p>6. Other Sites<br />
                We may forward Users to other web sites. Please be aware that this Site and Ken’s may not be responsible for the business and privacy practices of these other web sites. We encourage you to be aware of this when you leave our Site and to read the legal notices and privacy policies of each and every web site you visit.</p>
                <p>7. Notification of Changes<br />
                If we decide to change our Policy, we will post these changes on this Site or provide other notification of our revised Policy so our Users are always aware of what information we collect, how we use it, and when we disclose it.</p>
                <p>8. Children’s Online Privacy Protection<br />
                This Site serves general users of the World Wide Web. This Site is intended for adult or adult supervised use. We support and comply with the Children’s Online Protection Act (COPPA) and we do not knowingly collect information from children under the age of 13, nor do we share such information with third parties. UNDER NO CIRCUMSTANCES ARE PERSONS 13 YEARS OF AGE OR YOUNGER PERMITTED TO SUBMIT CONTACT, DEMOGRAPHIC OR BILLING INFORMATION. Children under the age of 18 may use this website only with the involvement of a parent or guardian.</p>
                <p>9. Product Locator<br />
                The ITEMFINDER SERVICES provided via the website are authorized by the owners of U.S. Patent 5,930,474.</p>
            </div>
        );
    }
}

export default Privacy;
